<template>
  <div>
    <div><el-button type="success" @click="edit">新增课件</el-button></div>
    <div class="ls_flex">
      <el-date-picker
        v-model="time"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
      >
      </el-date-picker>
      <el-input v-model="query.title" placeholder="课程名字搜索"></el-input>
      <el-select v-model="query.publishStatus" placeholder="请选择">
        <el-option label="全部状态" :value="null"> </el-option>
        <el-option label="未发布" :value="0"> </el-option>
        <el-option label="已发布" :value="1"> </el-option>
      </el-select>
      <el-button
        type="primary"
        @click="
          query.pageIndex = 1;
          getList();
        "
        >查询</el-button
      >
    </div>
    <el-table style="width: 100%" :data="list">
      <el-table-column prop="title" label="课件名称"> </el-table-column>
      <el-table-column prop="name" label="发布范围">
        <template slot-scope="scope">
          <div style="color: #999; margin-bottom: 15px">
            {{ scope.row.range }}
          </div>
          <div
            style="
              color: rgb(243, 57, 57);
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            "
            :title="scope.row.organization"
          >
            {{ scope.row.organization }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="publishTime" label="发布时间"> </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          {{ scope.row.publishStatus === 1 ? "已发布" : "未发布" }}
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作">
        <template slot-scope="scope">
          <div v-if="scope.row.publishStatus === 1">
            <el-button type="text" @click="publish(scope.row)">撤回</el-button>
          </div>
          <div v-else>
            <el-button type="text" @click="publish(scope.row)">发布</el-button>
            <el-button type="text" @click="edit(scope.row)">编辑</el-button>
            <el-button type="text" @click="del(scope.row.id)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      :current-page.sync="query.pageIndex"
      @current-change="getList"
      :page-size="query.pageSize"
      style="text-align: center; margin-top: 15px"
    >
    </el-pagination>
    <el-dialog
      :title="(form.id ? '修改' : '新增') + '课件'"
      :visible.sync="show"
      width="30%"
    >
      <el-form label-width="80px">
        <el-form-item label="课件标题">
          <el-input
            v-model="form.title"
            maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="课件地址">
          <el-input v-model="form.url"></el-input>
        </el-form-item>
        <el-form-item label="课件描述">
          <el-input
            v-model="form.coursewareDesc"
            maxlength="200"
            :rows="5"
            type="textarea"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="可用版本">
          <div style="padding-top: 10px">
            <el-checkbox-group v-model="range">
              <el-checkbox
                :label="item.id"
                v-for="item in versionList"
                :key="item.id"
              >
                {{ item.version }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </el-form-item>
        <el-form-item label="可用学校">
          <el-checkbox-group v-model="organizationId">
            <el-checkbox
              :label="item.id"
              v-for="item in organizationList"
              :key="item.id"
            >
              {{ item.name }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="">
          <el-button @click="show = false">取消</el-button>
          <el-button type="primary" @click="confirm">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      total: 0,
      query: {
        pageIndex: 1,
        pageSize: 10,
        startTime: "",
        endTime: "",
        publishStatus: null,
        title: "",
      },
      show: false,
      form: {
        id: null,
        title: "",
        url: "",
        coursewareDesc: "",
        ranges: "",
        organizationIds: "",
      },
      range: [],
      organizationId: [],
      versionList: [],
      organizationList: [],
      time: [],
    };
  },
  created() {
    this.$ajax
      .post("basic/version/getPage", { pageNum: 1, pageSize: 100 })
      .then((res) => {
        this.versionList = res.list;
      });
    Promise.all([
      this.$ajax.post("institution/play/getList", {
        pageNum: 1,
        pageSize: 100,
      }),
      this.$ajax.post("institution/getList", { pageNum: 1, pageSize: 100 }),
    ]).then((res) => {
      this.organizationList = [...res[0].list, ...res[1].list];
    });
    this.getList();
  },
  methods: {
    del(id) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$ajax
            .get("/admin/courseware/delete", {
              params: { id },
            })
            .then((res) => {
              this.$message.success("删除成功");
              this.getList();
            });
        })
        .catch(() => {});
    },
    edit(item) {
      this.range = [];
      this.organizationId = [];
      if (item) {
        this.form.id = item.id;
        this.form.title = item.title;
        this.form.url = item.url;
        this.form.coursewareDesc = item.coursewareDesc;
        if (item.range) {
          let range = item.range.split(",");
          for (let item of range) {
            let mate = this.versionList.find((oItem) => {
              return oItem.version === item;
            });
            if (mate) {
              this.range.push(mate.id);
            }
          }
        }
        if (item.organization) {
          let organization = item.organization.split(",");
          for (let item of organization) {
            let mate = this.organizationList.find((oItem) => {
              return oItem.name === item;
            });
            if (mate) {
              this.organizationId.push(mate.id);
            }
          }
        }
      } else {
        this.form.id = "";
        this.form.title = "";
        this.form.url = "";
        this.form.coursewareDesc = "";
      }
      this.show = true;
    },
    publish(item) {
      this.$ajax
        .post("/admin/courseware/publish", {
          id: item.id,
          publishStatus: item.publishStatus ? 0 : 1,
        })
        .then((res) => {
          this.$message.success("修改成功");
          this.getList();
        });
    },
    confirm() {
      this.form.ranges = this.range.join(",");
      this.form.organizationIds = this.organizationId.join(",");
      this.$ajax.post("/admin/courseware/save", this.form).then((res) => {
        this.$message.success("保存成功");
        this.show = false;
        this.getList();
      });
    },
    getList() {
      this.$ajax.post("/admin/courseware/pageList", this.query).then((res) => {
        this.list = res.list;
        this.total = res.total;
      });
    },
  },
  watch: {
    time(val) {
      if (!val) {
        this.query.startTime = "";
        this.query.endTime = "";
        return;
      }
      this.query.startTime = val[0];
      this.query.endTime = val[1];
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 20px;
  color: rgb(153, 153, 153);
  margin: 15px 0;
}
</style>